import { Alert } from '@ctw/shared/components/alert';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { ZusAggregatedProfile } from '@ctw/shared/content/zus-aggregated-profile';
import type { ZapIFrameConfig } from '@ctw/shared/content/zus-aggregated-profile-iframe';
import { CTWProvider } from '@ctw/shared/context/ctw-context';
import { PatientProvider } from '@ctw/shared/context/patient-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { useZapContext } from '@ctw/shared/context/zap-context';
import { isAuthTokenExpired } from '@ctw/shared/utils/auth';
import { tw } from '@ctw/shared/utils/tailwind';
import { debounce } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  sendOnAddToRecord,
  sendOnEhrWriteback,
  sendOnPatientSave,
  sendOnResourceSave,
} from '../utils/send-zap-message-event';

export function ZapV2() {
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [searchParams] = useSearchParams();
  const telemetry = useTelemetry();
  const { registerZapEventListener, unregisterZapEventListener } = useZapContext();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    telemetry.logger.info('Mounting zap in child frame');

    const onAddToRecord: Parameters<typeof registerZapEventListener>[1] = (event) => {
      void sendOnAddToRecord(
        telemetry.logger,
        zapConfig?.CTWProviderProps.ehr ?? 'unknown',
        event.payload.resource,
      );
    };

    registerZapEventListener('addToRecord', onAddToRecord);

    debouncedPostRefreshMessage();
    return () => {
      telemetry.logger.info('Unmounting zap from child frame');

      unregisterZapEventListener('addToRecord', onAddToRecord);
    };
  }, [telemetry]);

  const zapConfig = useMemo(() => {
    try {
      return JSON.parse(searchParams.get('config') ?? 'null') as ZapIFrameConfig;
    } catch (_error) {
      return null;
    }
  }, [searchParams]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const handleTokenReceivedMessage = (event: {
      data: { type: string; payload: { accessToken: string } };
    }) => {
      if (event.data.type === 'ZusToken') {
        setAccessToken(event.data.payload.accessToken);
      }
    };

    window.addEventListener('message', handleTokenReceivedMessage);
    return () => {
      window.removeEventListener('message', handleTokenReceivedMessage);
    };
  }, [setAccessToken]);

  const postRefreshMessage = () => {
    window.parent.postMessage(
      {
        type: 'ZusTokenRequest',
      },
      '*',
    );
  };

  const debouncedPostRefreshMessage = debounce(postRefreshMessage, 10_000, {
    leading: true,
  });

  useEffect(() => {
    if (isAuthTokenExpired(accessToken ?? '')) {
      debouncedPostRefreshMessage();
    }
  }, [accessToken, debouncedPostRefreshMessage]);

  if (!accessToken) {
    return <LoadingSpinner centered={true} message="Loading..." />;
  }

  if (!zapConfig) {
    return (
      <div className={tw`w-full`}>
        <Alert type="error" header="Missing Zus Aggregated Profile Configuration" />
      </div>
    );
  }

  return (
    <CTWProvider
      onAuthTokenExpiration={() => {
        debouncedPostRefreshMessage();
      }}
      {...zapConfig.CTWProviderProps}
      builderId={zapConfig.CTWProviderProps.builderId}
      service="zap"
      serviceVersion={import.meta.env.VITE_GIT_SHA}
      serviceVariant="zap-v2"
      authToken={accessToken}
      onResourceSave={(resource, action, error) =>
        sendOnResourceSave(telemetry.logger, resource, action, error)
      }
      writebacks={{
        onWritebackSuccess: (_id, response) => {
          sendOnEhrWriteback(response);
        },
      }}
      featureFlags={{
        ...zapConfig.CTWProviderProps.featureFlags,
      }}
    >
      <PatientProvider
        {...zapConfig.PatientProviderProps}
        onPatientSave={(data) => sendOnPatientSave(telemetry.logger, data)}
      >
        <ZusAggregatedProfile />
      </PatientProvider>
    </CTWProvider>
  );
}

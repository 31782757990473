import { PageError } from '@ctw/shared/components/errors/page-error';
import { ZapProvider } from '@ctw/shared/context/zap-context';
import { ZuiProvider, isZapVersion } from '@ctw/shared/context/zui-provider';
import { trimStart } from 'lodash-es';
import { useMemo } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { SalesforceV1 } from './zaps/salesforce-v1';
import { ZapV1 } from './zaps/zap-v1';
import { ZapV2 } from './zaps/zap-v2';

const RouteDefinitions = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const zapVersion = useMemo(() => {
    if (location.pathname === '/') {
      const url = new URL(window.location.href);
      url.pathname = '/v1';
      window.location.href = url.toString();
      return '';
    }

    const maybeZapVersion = trimStart(location.pathname, '/').split('/')[0];
    return isZapVersion(maybeZapVersion) ? maybeZapVersion : 'v1';
  }, []);

  const isSalesforce = useMemo(
    () => window.location.pathname.startsWith('/salesforce'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <ZuiProvider
      service="zap"
      navigate={navigate}
      navigateBasePath={isSalesforce ? '/salesforce/v1' : `/${zapVersion}`}
      navigatePersistentQueryParams={['config']}
      pathname={useLocation}
    >
      <ZapProvider>
        <Routes>
          <Route path="/v1/*" element={<ZapV1 />} />
          <Route path="/v2/*" element={<ZapV2 />} />
          <Route path="/salesforce/v1/*" element={<SalesforceV1 />} />
          <Route path="/health" element={<>OK</>} />
        </Routes>
      </ZapProvider>
    </ZuiProvider>
  );
};

interface AppProps {
  isInsideIFrame: boolean;
}

export function App({ isInsideIFrame }: AppProps) {
  if (!isInsideIFrame) {
    return (
      <PageError
        title="Proxy Authentication Required"
        message="This page will only authenticate and load when embedded inside an iframe. For assistance, get in contact with your representative at Zus Health."
        errorCode="HTTP ERROR 407"
        supportEmailSubject="Help Embedding Zus Aggregated Profile"
      />
    );
  }
  return (
    <BrowserRouter>
      <RouteDefinitions />
    </BrowserRouter>
  );
}

import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { tw } from '@ctw/shared/utils/tailwind';
import { type User, UserManager, type UserManagerSettings } from 'oidc-client-ts';
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getSalesforceCanvasEnvironment } from '../utils/get-salesforce-canvas-environment';

interface SalesforceUserContextProps {
  user?: User;
}

const defaultContextValue: SalesforceUserContextProps = {};

export const SalesforceUserContext = createContext<SalesforceUserContextProps>(defaultContextValue);

export const SalesforceUserProvider = ({ children }: PropsWithChildren<object>) => {
  const [user, setUser] = useState<User>();

  const sfEnv = getSalesforceCanvasEnvironment();

  useEffect(() => {
    const runAuthFlow = async () => {
      try {
        const oidcConfig: UserManagerSettings = {
          authority: sfEnv.targetOrigin,
          client_id: sfEnv.clientId,
          redirect_uri: `${window.location.origin}/salesforce/v1?clientId=${sfEnv.clientId}&target_origin=${sfEnv.targetOrigin}`,
          response_type: 'code',
          scope: 'api',
          post_logout_redirect_uri: `${window.location.origin}/salesforce/v1`,
          automaticSilentRenew: true,
        };

        const userManager = new UserManager(oidcConfig);

        const isAuthCallback = !!new URLSearchParams(window.location.search).has('code');
        if (isAuthCallback) {
          setUser(await userManager.signinCallback());

          // oidc-client-ts stores the tokens in sessionStorage, but we don't need them to persist
          // after the user has been written to state
          // so clear them out for safety sake
          for (const key of Object.keys(sessionStorage)) {
            if (key.startsWith('oidc.')) {
              sessionStorage.removeItem(key);
            }
          }
        } else {
          void userManager.signinRedirect();
        }
      } catch (_error) {
        throw new Error('An error occurred during Salesforce authentication.');
      }
    };

    void runAuthFlow();
  }, [sfEnv.clientId, sfEnv.targetOrigin]);

  const contextValue = useMemo(() => ({ user }), [user]);

  if (!user) {
    return (
      <LoadingSpinner
        className={tw`fixed inset-0 flex h-screen items-center justify-center`}
        message="Getting Salesforce user"
      />
    );
  }

  return (
    <SalesforceUserContext.Provider value={contextValue}>{children}</SalesforceUserContext.Provider>
  );
};

export const useSalesforceUser = () => useContext(SalesforceUserContext);

import { withErrorBoundary } from '@ctw/shared/components/errors/error-boundary';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';

const isInsideIFrame = window.location !== window.parent.location;
const container = document.getElementById('root') as HTMLDivElement;

const Root = withErrorBoundary({
  boundaryName: 'ZAP Root',
  includeTelemetryBoundary: true,
  Component: () => (
    <StrictMode>
      <App isInsideIFrame={isInsideIFrame} />
    </StrictMode>
  ),
});

createRoot(container).render(<Root />);

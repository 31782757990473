import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { tw } from '@ctw/shared/utils/tailwind';
import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getSalesforceCanvasEnvironment } from '../utils/get-salesforce-canvas-environment';
import { useSalesforceUser } from './salesforce-user-context';

declare global {
  interface Window {
    Sfdc: {
      canvas: {
        client: {
          ctx: (
            callback: (msg: {
              status: number;
              payload: {
                environment: { record: { Id: string } };
                organization: { organizationId: string };
              };
            }) => void,
            client: unknown,
          ) => void;
        };
        oauth: {
          client: () => {
            oauthToken?: string;
            targetOrigin?: string;
            instanceId?: string;
          };
        };
      };
    };
  }
}

export type SalesforceEnvironment = {
  organizationId: string;
  accountId: string;
};

interface SalesforceEnvironmentContextProps {
  environment?: SalesforceEnvironment;
}

const defaultContextValue: SalesforceEnvironmentContextProps = {};

export const SalesforceEnvironmentContext =
  createContext<SalesforceEnvironmentContextProps>(defaultContextValue);

export const SalesforceEnvironmentProvider = ({ children }: PropsWithChildren<object>) => {
  const [environment, setEnvironment] = useState<SalesforceEnvironment>();
  const [isCanvasLoaded, setIsCanvasLoaded] = useState(false);
  const salesforceUser = useSalesforceUser();
  const sfEnv = getSalesforceCanvasEnvironment();

  useEffect(() => {
    const loadCanvasScript = () => {
      const script = document.createElement('script');
      script.src = `${sfEnv.targetOrigin}/canvas/sdk/js/62.0/canvas-all.js`;
      script.onload = () => setIsCanvasLoaded(true);
      document.body.appendChild(script);
    };

    if (typeof window.Sfdc === 'undefined') {
      loadCanvasScript();
    } else {
      setIsCanvasLoaded(true);
    }
  }, [sfEnv.targetOrigin]);

  useEffect(() => {
    if (!isCanvasLoaded) {
      return;
    }

    if (typeof window.Sfdc === 'undefined') {
      throw new Error('Salesforce Canvas SDK failed to load');
    }

    // TODO - comment expaining more about this
    const sfClient = window.Sfdc.canvas.oauth.client();
    sfClient.oauthToken = salesforceUser.user?.access_token;
    sfClient.targetOrigin = sfEnv.targetOrigin;
    sfClient.instanceId = sfEnv.instanceId;

    const fetchSalesforceContext = () => {
      window.Sfdc.canvas.client.ctx((msg) => {
        if (msg.status !== 200) {
          throw new Error('Failed to get Salesforce context');
        }

        setEnvironment({
          accountId: msg.payload.environment.record.Id,
          organizationId: msg.payload.organization.organizationId,
        });
      }, sfClient);
    };

    fetchSalesforceContext();
  }, [salesforceUser, isCanvasLoaded, sfEnv.targetOrigin, sfEnv.instanceId]);

  const contextValue = useMemo(() => ({ environment }), [environment]);

  if (!environment) {
    return (
      <LoadingSpinner
        className={tw`fixed inset-0 flex h-screen items-center justify-center`}
        message="Getting Salesforce context"
      />
    );
  }

  return (
    <SalesforceEnvironmentContext.Provider value={contextValue}>
      {children}
    </SalesforceEnvironmentContext.Provider>
  );
};

export const useSalesforceEnvironment = () => useContext(SalesforceEnvironmentContext);

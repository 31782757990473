import type { PatientFormData } from '@ctw/shared/content/forms/actions/patients';
import {
  ZapOnAddToRecordMessageType,
  ZapOnEHRWritebackMessageType,
  ZapOnPatientSaveMessageType,
  ZapOnResourceSaveMessageType,
} from '@ctw/shared/content/zus-aggregated-profile-iframe';
import type { EhrWriteResponse } from '@ctw/shared/context/writeback-provider';
import type { IScopedDelegatedLogger } from '@ctw/shared/utils/scoped-delegated-logger';
import type { FhirResource, Resource } from 'fhir/r4';
import { MessageEventPromise } from './message-event-promise';

export function sendOnResourceSave(
  logger: IScopedDelegatedLogger,
  resource: Resource,
  action: string,
  error?: Error,
) {
  const data = {
    error: error?.message,
    resource,
    action,
  };
  window.parent.postMessage(
    {
      type: ZapOnResourceSaveMessageType,
      payload: data,
      // todo: remove deprecated "resource", "action", "error"
      resource,
      action,
      error,
    },
    '*',
  );

  logger.debug('sendOnResourceSave: sent message to parent window', {
    data: {
      resource: {
        resourceType: resource.resourceType,
        id: resource.id,
      },
      action,
      error,
    },
  });
}

export function sendOnEhrWriteback(data: EhrWriteResponse) {
  window.parent.postMessage({ type: ZapOnEHRWritebackMessageType, payload: data }, '*');
}

export function sendOnPatientSave(
  logger: IScopedDelegatedLogger,
  patientFormData: PatientFormData,
) {
  window.parent.postMessage(
    {
      type: ZapOnPatientSaveMessageType,
      payload: patientFormData,
    },
    '*',
  );
  logger.debug('sendOnPatientSave: sent message to parent window');
}

export function sendOnAddToRecord(
  logger: IScopedDelegatedLogger,
  ehr: string,
  resource: FhirResource,
) {
  // Backwards compatibility for Canvas' onAddToRecordHandler
  // See: https://github.com/zeus-health/ctw/blob/321a33374aad05461447e64c0c4779de58bd5bc1/packages/ctw-component-library/src/components/content/zus-aggregated-profile/zus-aggregated-profile-iframe.tsx#L110
  const component = ehr === 'canvas' ? 'medications-all' : undefined;

  // Send an "onResourceSave" message to the parent window and wait for the response.
  const messagePromise = new MessageEventPromise(ZapOnAddToRecordMessageType).sendMessage({
    component,
    resource,
  });

  logger.debug('sendOnAddToRecord: sent message to parent window', {
    data: {
      resource: {
        resourceType: resource.resourceType,
        id: resource.id,
      },
    },
  });

  return messagePromise;
}

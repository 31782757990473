import { omitNil } from '@ctw/shared/utils/omit-nil';
import { tw } from '@ctw/shared/utils/tailwind';
import { type IconDefinition, faFileCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface PageErrorProps {
  icon?: IconDefinition;
  title: string;
  message?: string;
  errorCode?: string;
  supportEmailSubject?: string;
  supportEmailBody?: string;
}

export const PageError = ({
  icon,
  title,
  message,
  errorCode,
  supportEmailSubject,
  supportEmailBody,
}: PageErrorProps) => {
  // Note: this component somewhat duplicates link rendering because we can't rely on being in a
  // a ZUI or telemetry context
  const mailToParams = new URLSearchParams(
    omitNil({
      subject: supportEmailSubject,
      body: supportEmailBody,
    }),
  );

  return (
    <div className={tw`mt-20 ml-10 flex flex-col flex-wrap space-y-12 sm:ml-20 lg:ml-80`}>
      <FontAwesomeIcon
        icon={icon ?? faFileCircleXmark}
        className={tw`w-min max-w-min text-[80px] text-content-subtle`}
      />
      <div className={tw`space-y-6`}>
        <div className={tw`font-medium text-2xl`}>{title}</div>
        {message && <div className={tw`font-medium text-base`}>{message}</div>}
        {errorCode && (
          <div className={tw`font-medium text-content-subtle text-xs`}>{errorCode}</div>
        )}
        <div>
          <a
            href={`mailto:support@zushealth.com?${mailToParams.toString().replaceAll('+', '%20')}`}
            className={tw`cursor-pointer rounded-md fill-primary-text font-medium font-sans text-primary-text no-underline hover:underline focus:no-underline focus-visible:outline-4 focus-visible:outline-primary-main focus-visible:outline-offset-[3px] active:scale-[.98]`}
          >
            Contact support@zushealth.com
          </a>
        </div>
      </div>
    </div>
  );
};

type MessageEventData = {
  id?: string;
  type: string;
  payload?: unknown;
  error?: string;
};

type UnknownCallback = (data: unknown) => void;

export class MessageEventPromise {
  // Map to store resolve and reject functions for pending promises
  private messageSettledFns: Map<string, { resolve: UnknownCallback; reject: UnknownCallback }> =
    new Map();

  // To add event handlers that listen for message with type but no ID we use key "nil"
  private nilMessageId = 'nil';

  private messageType: string;
  private targetWindow: Window;

  public constructor(messageType: string, targetWindow: Window = window.parent) {
    this.messageType = messageType;
    this.targetWindow = targetWindow;
    window.addEventListener('message', this.handleMessage.bind(this));
  }

  private handleMessage(event: MessageEvent<MessageEventData>): void {
    // Check if the message is from the target window, is the correct type, and has a pending promise
    if (event.source === this.targetWindow && event.data.type === this.messageType) {
      const messageId = event.data.id ?? this.nilMessageId;
      const handlers = this.messageSettledFns.get(messageId);
      if (event.data.error) {
        // Reject the pending promise with the message error
        handlers?.reject(event.data.error);
      } else {
        // Resolve the pending promise with the message payload
        handlers?.resolve(event.data.payload);
      }
      // Remove the resolve function from our map
      this.messageSettledFns.delete(messageId);
    }
  }

  // Method to send a message and wait for a response
  public sendMessage(message: unknown): Promise<unknown> {
    // Generate a unique ID for this message
    const messageId = Math.random().toString(36).substring(2);

    // Create a data object with the message and ID
    const data: MessageEventData = {
      type: this.messageType,
      payload: message,
      id: messageId,
    };

    // Send the message to the target window
    this.targetWindow.postMessage(data, '*');

    return this.awaitMessage(messageId);
  }

  public awaitMessage(messageId: string = this.nilMessageId): Promise<unknown> {
    return new Promise((resolve, reject) => {
      // Store the resolve and reject functions in a map with the message ID as key
      this.messageSettledFns.set(messageId, { resolve, reject });
    });
  }
}

import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { ZusAggregatedProfile } from '@ctw/shared/content/zus-aggregated-profile';
import {
  type ZapIFrameConfig,
  ZapIFrameConfigMessageType,
  ZapIFrameReadyMessageType,
  ZapOnConfigReceivedMessageType,
} from '@ctw/shared/content/zus-aggregated-profile-iframe';
import { CTWProvider } from '@ctw/shared/context/ctw-context';
import { PatientProvider } from '@ctw/shared/context/patient-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/telemetry-boundary';
import { useZapContext } from '@ctw/shared/context/zap-context';
import { omit } from 'lodash-es';
import { useEffect, useState } from 'react';
import {
  sendOnAddToRecord,
  sendOnEhrWriteback,
  sendOnPatientSave,
  sendOnResourceSave,
} from '../utils/send-zap-message-event';

// todo: replace this with the imported ZapMessageEvent from @ctw/shared once v1.63.0 is deployed to all EHRs
type ZapIFrameConfigMessage = {
  type: string;
  config?: ZapIFrameConfig; // deprecated
  payload: ZapIFrameConfig;
};

export function ZapV1() {
  const [zapConfig, setZapConfig] = useState<ZapIFrameConfig | undefined>(undefined);
  const { registerZapEventListener, unregisterZapEventListener } = useZapContext();
  const telemetry = useTelemetry();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    telemetry.logger.info('Mounting zap in child frame');

    const onAddToRecord: Parameters<typeof registerZapEventListener>[1] = (event) => {
      void sendOnAddToRecord(
        telemetry.logger,
        zapConfig?.CTWProviderProps.ehr ?? 'unknown',
        event.payload.resource,
      );
    };

    registerZapEventListener('addToRecord', onAddToRecord);

    return () => {
      telemetry.logger.info('Unmounting zap from child frame');

      unregisterZapEventListener('addToRecord', onAddToRecord);
    };
  }, [telemetry, zapConfig]);

  useEffect(() => {
    const handler = ({ data }: { data: ZapIFrameConfigMessage }) => {
      if (data.type === ZapIFrameConfigMessageType) {
        const payload = data.config ?? data.payload;
        telemetry.logger.debug('received ZapIFrameConfig message in child frame', {
          ...payload,
          CTWProviderProps: omit(payload.CTWProviderProps, ['authToken', 'headers', 'theme']),
        });
        // todo: remove deprecated config
        setZapConfig(data.config ?? data.payload);

        window.parent.postMessage(
          {
            type: ZapOnConfigReceivedMessageType,
          },
          '*',
        );
        telemetry.logger.debug('sendOnIFrameConfigReceived: sent message to parent window');
      }
    };

    telemetry.logger.debug('adding ZapIFrameConfig event listener in child frame');
    window.addEventListener('message', handler);

    window.parent.postMessage(
      {
        type: ZapIFrameReadyMessageType,
      },
      '*',
    );

    telemetry.logger.debug('sendOnIFrameReady: sent message to parent window');

    // clean up
    return () => {
      telemetry.logger.debug('cleaning up ZapIFrameConfig event listener in child frame');
      window.removeEventListener('message', handler);
    };
  }, [telemetry]);

  if (!zapConfig) {
    return <LoadingSpinner centered={true} message="Loading..." />;
  }

  return (
    <CTWProvider
      {...zapConfig.CTWProviderProps}
      builderId={zapConfig.CTWProviderProps.builderId}
      ehr={zapConfig.CTWProviderProps.ehr}
      service="zap"
      serviceVersion={import.meta.env.VITE_GIT_SHA}
      serviceVariant="zap-v1"
      onResourceSave={(resource, action, error) =>
        sendOnResourceSave(telemetry.logger, resource, action, error)
      }
      writebacks={{
        onWritebackSuccess: (_id, data) => {
          sendOnEhrWriteback(data);
        },
      }}
      featureFlags={{
        ...zapConfig.CTWProviderProps.featureFlags,
      }}
    >
      <PatientProvider
        {...zapConfig.PatientProviderProps}
        onPatientSave={(data) => sendOnPatientSave(telemetry.logger, data)}
      >
        <ZusAggregatedProfile />
      </PatientProvider>
    </CTWProvider>
  );
}

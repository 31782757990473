import type { CTWProviderProps } from '@ctw/shared/context/ctw-context';
import type { PatientResourceID, ThirdPartyID } from '@ctw/shared/context/patient-provider';

export type ZapIFrameConfig = {
  CTWProviderProps: CTWProviderProps;
  PatientProviderProps: ThirdPartyID | PatientResourceID;
};

export const ZapIFrameConfigMessageType = 'ZusAggregatedProfileIFrameConfig';
export const ZapIFrameReadyMessageType = 'ZusAggregatedProfileIFrameReady';
export const ZapOnResourceSaveMessageType = 'ZusAggregatedProfileOnResourceSave';
export const ZapOnEHRWritebackMessageType = 'ZusAggregatedProfileOnEHRWriteback';
export const ZapOnPatientSaveMessageType = 'ZusAggregatedProfileOnPatientSave';
export const ZapOnAddToRecordMessageType = 'ZusAggregatedProfileOnAddToRecord';
export const ZapOnConfigReceivedMessageType = 'ZusAggregatedProfileConfigReceived';
